<template>
  <div>
    <div class="vs-row hero">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">Search Results</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="mx-10">
      <div class="vs-row flex border mt-3 rounded p-2 mt-7">
        <div class="vs-col lg:w-1/6 sm:w-1/5 w-full text-center">
          <vs-avatar class="self-center" size="90px" :src="member.child_photo"/>
          <p class="text-base">{{member.child_name}}</p>
          <p class="text-sm text-gray-400">{{member.child_age}} Years Old</p>
        </div>
        <div class="vs-col sm:border-l-2 px-5 py-2 lg:w-5/6 sm:w-4/5 w-full self-center">
          <p class="font-medium">Kid Info</p>
          <p class="text-gray-400 lg:w-1/3 sm:w-2/3">Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
          <div class="vs-col flex divide-x divide-gray-300 text-sm pt-3">
            <span class="cursor-pointer pr-2 text-blue flex">
              <vs-icon size="15px" class="self-center" color="#00DCDC" icon="edit"></vs-icon>
              <span class="self-center">Edit</span>
            </span>
            <span @click="deleteMember()" class="cursor-pointer px-2 text-red-600 flex">
              <vs-icon size="15px" class="self-center" icon="delete"></vs-icon>
              <span class="self-center">Remove</span>
            </span>
          </div>
        </div>
      </div>
      <div class="mt-7 vs-row">
        <div class="vs-row">
          <p class="text-lg">{{interest.name}}</p>
        </div>
        <div class="mt-2 vs-row">
           <!-- :class="interestsChoosed.includes(item.sub_interests_id) ? 'selected-interest' : ''" -->
          <div :class="subInterests.includes(item.sub_interests_id) ? 'selected' : 'not-selected'" @click="addInterestToMember(item)" class="con-vs-chip chip-btn cursor-pointer" v-for="item in interest.sub_interests" :key="item.index">
            <p> {{item.sub_interests_name}}</p>
          </div>
        </div>
      </div>
      <div class="float-right vs-row mt-5 mb-20">
        <vs-button class="btn" @click="addChildInterest()">Save</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios.js'
export default {
    data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      member: {},
      interests: [],
      subInterests: [],
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Profile',
          url: 'profile',
        },
        {
          title: 'Manage Members',
          url: 'members',
        },
        {
          title: 'Member Details',
          url: `members/${this.$route.params.memberID}`,
        },
        {
          title: 'Sport',
          active: true
        }
      ],
      interest: {}
    }
  },
  methods: {
    addChildInterest () {
      const child = {
        child_id: Number(this.$route.params.memberID),
        interests_id: []
      }
      this.subInterests.forEach(element => {
        child.interests_id.push({
          "interests_id": String(element)
        })
      })
      const formData = new FormData()
      formData.append('child_id', this.$route.params.memberID)
      formData.append('interests_id', JSON.stringify(child.interests_id))

      axios.post('v1/user/addChildInterests', formData, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.loadInterests()
          this.loadMemberDetails()
          this.$vs.loading.close()
        }
      })
    },
    addInterestToMember (item) {
      if (!this.subInterests.includes(item.sub_interests_id)) {
        this.subInterests.push(item.sub_interests_id)
      } else {
        var index = this.subInterests.indexOf(item.sub_interests_id);
        if (index > -1) {
          this.subInterests.splice(index, 1);
        }
      }
    },
    loadInterests () {
      this.$vs.loading()
      axios.get('v1/interestsList', {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          response.data.payload.forEach(element => {
            if (parseInt(element.interests_id) === parseInt(this.$route.params.interestID)) {
              this.interest = element
            }
          })
          this.$vs.loading.close()
        }
      })
    },
    loadMemberDetails () {
      this.$vs.loading()
      axios.post('v1/user/userChildDetails', {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.member = response.data.payload.child_data.filter(member => Number(member.child_id) === Number(this.$route.params.memberID))
          this.member =this.member[0]
          this.member.child_interests.forEach(item => {
            this.subInterests.push(parseInt(item.interests_id))
          })
          this.$vs.loading.close()
        }
      })
    },
    deleteMember () {
      this.$vs.loading()
      axios.post('v1/user/removeChild', {child_id: this.member.child_id}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.$vs.loading.close()
        }
      })
    }
  },
  created () {
    this.loadInterests()
    this.loadMemberDetails()
  }
}
</script>

<style scoped>
.btn {
  padding: 7px 24px;
}
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.selected {
  color:white !important;
  background: #F89416;
}
.not-selected {
  background-color: #fff;
  color: #000;
}
.chip-btn {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px 28px 10px 20px;
  border-radius: 10px;
  margin: 5px;
  font-size: 13px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.topPicks {
  color: #00DCDC;
  border-bottom: 1px solid #00DCDC;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color:#00DCDC;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.cardBody .topPicksText {
  color:#000;
  padding: 10px 15px;
  width: 200px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 1.75rem;
  border-top-right-radius: 100px;
  text-align: left;
}
.cardBody .topPicksText:hover {
  color:#fff;
  background-color: #00DCDC;
}
.cardBody .btn:hover {
  background-color: #999;
}
/* .vs-input-primary > .vs-input--input:focus {
  border: 1px solid rgba(5, 218, 220, 1) !important;
}
.vs-input-primary.isFocus .vs-input--label, 
.vs-input-primary .vs-input--input:focus~.icon-inputx, 
.vs-input-primary .vs-input--input:focus~.vs-placeholder-label {
  color: #00DCDC !important;
} */
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #FFF;
  background-color: #F89416 !important;
  /* border-color: #409EFF; */
  box-shadow: -1px 0 0 0 #409eff;
}
</style>